/* eslint-disable no-unused-vars */
import {
  action, computed, observable, runInAction, toJS, makeObservable,
} from 'mobx';
import { Axios } from '../utilities/network';
import RootStore from './root-store';

export default class StudentStore {

  loading: boolean = false;

  loadingVisit: boolean = false;

  loadingTrainee: boolean = false;

  studentProfile: any = { totalCount: 0, data: [] };

  oneStudentProfile: any = {};

  traineeList: any = { totalCount: 0, data: [] };
  
  traineeshipVisitList: any = { totalCount: 0, data: [] };

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      loading: observable,
      loadingTrainee: observable,
      traineeList: observable,
      oneStudentProfile: observable,
      traineeshipVisitList: observable,
      getTraineeList: action,
      getVisitListByTraineeshipId: action,
    });

    this.rootStore = rootStore;
  }

  setLoadingVisit = (flag: boolean) => {
    this.loadingVisit = flag;
  }

  getTraineeList = async() => {
    try {
      const res = await Axios.get(`/api/Trainee/GetTraineeList`)
      this.traineeList.data = res.data
      this.traineeList.totalCount = res.data.length
      console.log('trainee list: ', res.data)
      return Promise.resolve(res.data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  // get visit list by traineeship id
  getVisitListByTraineeshipId = async (traineeshipId: number) => {
    this.loadingVisit = true;
    try {
      const res = await Axios.get(`/api/Visit/GetVisitListByTraineeshipId/${traineeshipId}`);
      this.traineeshipVisitList.totalCount = res.data.length
      this.traineeshipVisitList.data= res.data
      console.log("visit list by traineeship Id: ", res.data)
      return Promise.resolve(res.data)
    } catch(err) {
      return Promise.reject(err)
    } finally {
      this.loadingVisit = false;
    }
  }

  // Get one student 
  getStudentProfileById = async (studentId: number) => {
    this.loadingTrainee = true;
    try {
      const res = await Axios.get(`/api/trainee/GetStudentProfileById/${studentId}`);
      this.oneStudentProfile = res.data;
      console.log('get Student Profile By Id', res.data)
      return Promise.resolve(res.data);
    } catch (err) {
      console.log(err)
      return Promise.reject(err);
      
    } finally {
      this.loadingTrainee = false;
    }
  }




}
