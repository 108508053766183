import useStores from "../hooks/use-stores";

export const PAGE_SIZE = 8;

export enum CRUDOperation {
  CREATE = 'create',
  UPDATE = 'Update',
  DELETE = 'Delete',
}

export enum Language {
  ENGLISH = 'en-AU',
  CHINESE_SIMP = 'zh-CN',
  CHINESE_TRAD = 'zh-TW'
}

export const imageSize = {
  small: 'small',
  medium: 'medium',
  large: 'large',
};

export const questionType = {
  single: "Single",
  multiple: "Multiple",
  fillInBlank: "FillInBlank",
}

export enum FeedbackType {
  COMPETENT = "Competent",
  INCOMPETENT= "Incompetent",
  INCOMPLETED = "",
  CREDIT_TRANSFER = "Credit Transfer",
}


export enum NavParentCategory {
  DASHBOARD = 'Dashboard',
  STUDENT = 'Student',
  VISIT = 'Visit',
  MESSAGE = 'Message'
}

export const NavListByCategory = {
  "Student": [],
  "Visit": [],
  "Message": [],
}
export const isBrowser = typeof window !== "undefined";

export enum ApiDataListType {
  YEAR_LEVEL = "YearLevel",
  ACADEMIC = "Academic",
  SUBJECT = "Subject",
  DOMAIN = "Domain",
  CALENDAR = "Calendar",
  QUESTION = "Question",
  QUESTION_TYPE = "QuestionType",
  QUESTION_CATEGORY = "QuestionCategory",
  QUESTION_OPTION = "QuestionOption",
  QUESTION_KNOWLEDEGE_PT = "QuestionKnowledegePoint",
  TEACHER = "Teacher",
  PERMISSION_ROLE = "PermissionRole",
  STUDENT = "Student",
  EXAM = "Exam",
}

export enum DocumentType {
  TRAINER_PROFILE_FILE = "TrainerProfileFile",
  STUDENT_PROFILE_FILE = "StudentProfileFile",
  STUDENT_TRAINEESHIP_FILE = "StudentTraineeshipFile",
  VISIT_DOC = "VisitDocument",
}

export enum FileType {
  TRAINER_PROFILE_FILE = "TrainerProfileFile",
  STUDENT_PROFILE_FILE = "StudentProfileFile",
  STUDENT_TRAINEESHIP_FILE = "StudentTraineeshipFile",
  VISIT_DOCUMENT = "VisitDocument"
}


export enum FileCategory {
  VISIT_DOCUMENT = "VisitDocument",
  FORM_SIGNATURE = "FormSignature",
  FORM_PERSONALID = "FormPersonalId",
  FORM_PERSONAL_ID = "FORM_PERSONAL_ID"
}

export enum UserStatus {
  TEMPORARY = 0,
  ACTIVE = 1,
}

export const IsLoggedIn = () => {
  const { userStore } = useStores();
  return userStore.isAuthed && userStore.userToken !== "" && (
    typeof window != 'undefined' && localStorage.USER_TOKEN && localStorage.USER_INFO);
}

export const GetUserStatus = () => {
  return UserStatus.TEMPORARY;
}

export const ProdEnvCheck = (dataList: any) => {
  if (typeof window !== 'undefined') {
    for (var data of dataList) if (!data) return false;
    return true;
  }
  return false;
}

export const GetCollegeId = () => {
  if (ProdEnvCheck([localStorage.USER_INFO])) {
    return JSON.parse(localStorage.USER_INFO).collegeId;
  }
  return 0;
}

export const GetCurrentSemester = () => {
  if (ProdEnvCheck([localStorage.CALENDAR])) {
    return JSON.parse(localStorage.CALENDAR);
  }
  return 0;
}

export const ApiDataResponseListLengthCheck = (listType: ApiDataListType) => {
  // const { academicStore, calendarStore, questionStore, teacherStore, permissionStore, studentStore, examStore } = useStores();
  // // Academic
  // if (listType === ApiDataListType.YEAR_LEVEL) return academicStore.yearLevelList.length;
  // if (listType === ApiDataListType.SUBJECT) return academicStore.subjectList.length;
  // if (listType === ApiDataListType.ACADEMIC) return academicStore.academicSettingList.length;
  // if (listType === ApiDataListType.DOMAIN) return academicStore.domainList.length;
  // // School Calendar
  // if (listType === ApiDataListType.CALENDAR) return calendarStore.calendarSettingList.length;
  // // Question
  // if (listType === ApiDataListType.QUESTION) return questionStore.questionList.length;
  // if (listType === ApiDataListType.QUESTION_TYPE) return questionStore.questionTypeList.length;
  // if (listType === ApiDataListType.QUESTION_CATEGORY) return questionStore.questionCatList.length;
  // // Teacher
  // if (listType === ApiDataListType.TEACHER) return teacherStore.teacherProfileList.length;
  // // Permission
  // if (listType === ApiDataListType.PERMISSION_ROLE) return permissionStore.roleDetailList.length;
  // // Student
  // if (listType === ApiDataListType.STUDENT) return studentStore.studentProfileList.length;
  // // Exam
  // if (listType === ApiDataListType.EXAM) return examStore.examList.length;

}

export const GetCurrentConvertedDateTime = () => {
  return new Date().toISOString().split('T')[0] + "T" + new Date().toLocaleTimeString();
}
