import React from 'react';
import { enableStaticRendering } from 'mobx-react';
import RootStore from './root-store';
import UserStore from './user-store';
import TeacherStore from './visit-store';
import StudentStore from './student-store';
import VisitStore from './visit-store';
import MessageStore from './message-store';
import FileStore from './file-store';

type CompositeStore = {
  rootStore: RootStore,
  userStore: UserStore,
  studentStore: StudentStore,
  visitStore: VisitStore,
  fileStore: FileStore,
  messageStore: MessageStore,
};

const isServer = typeof window === 'undefined';
// eslint-disable-next-line react-hooks/rules-of-hooks
enableStaticRendering(isServer);

// let _stores: null | CompositeStore = null;

const initStores = () => {
  const rootStore = new RootStore();
  const initializedStores: CompositeStore = {
    rootStore,
    userStore: rootStore.userStore,
    studentStore: rootStore.studentStore,
    visitStore: rootStore.visitStore,
    fileStore: rootStore.fileStore,
    messageStore: rootStore.messageStore,
  };
  return initializedStores;
};

export const stores = initStores(); // _stores || initStores();

export const StoreContext = React.createContext(stores);

export const StoreProvider = ({ children }) => <StoreContext.Provider value={stores}>{children}</StoreContext.Provider>;
